


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TContact } from '@/_types/contact.type';
import { TEvent, TEventSettings } from '@/_types/event.type';
import CabinetMainPage from '@/_modules/promo-cabinet/components/cabinet-main-page/cabinet-main-page.vue';
import ConfirmUnsavedLeave from '@/views/components/popups/confirm-unsaved-leave.vue';

enum CabinetTab {
  MAIN = 'main',
  LOBBY = 'lobby',
  PROGRAM = 'program',
  MAP = 'map',
  EXPONENTS = 'exponents',
  VISITORS = 'visitors',
  STATISTICS = 'statistics',
  EVENT_SETTINGS = 'eventSettings',
  MEETINGS = 'meetings',
  DISCUSSIONS = 'discussions',
  NEWS = 'news',
}

@Component({
  components: {
    CabinetMainPage,
    ConfirmUnsavedLeave,
  },
  computed: {
    ...mapGetters({
      contact: 'contact/promoPageStore',
      promoPage: 'promoPage/promoPageStore',
      isPromoPageLoading: 'isPromoPageLoading/promoPageStore',
      event: '_eventStore/event',
      eventSettings: '_eventStore/eventSettings',
    }),
  },
})
export default class Cabinet extends Vue {

  public readonly CabinetTab: typeof CabinetTab = CabinetTab;

  public readonly event: TEvent;
  public readonly contact: TContact;
  public readonly eventSettings: TEventSettings;

  public get isNavigationVisible(): boolean {
    return !this.$route.meta.hideCabinetNavigation;
  }

  public get isShowMeetingsSection(): boolean {
    return (this.eventSettings && this.eventSettings.show_meetings);
  }

  public get currentTab(): CabinetTab {
    switch (this.$route.name) {

      case 'promo-page-cabinet-lobby':
        return CabinetTab.LOBBY;

      case 'promo-page-cabinet-exponents':
        return CabinetTab.EXPONENTS;

      case 'promo-page-cabinet-event-map':
        return CabinetTab.MAP;

      case 'promo-page-cabinet-contacts':
        return CabinetTab.VISITORS;

      case 'promo-page-cabinet-event-settings':
        return CabinetTab.EVENT_SETTINGS;

      case 'promo-page-cabinet-main-page':
        return CabinetTab.MAIN;

      case 'promo-page-cabinet-program':
      case 'promo-page-cabinet-program-create':
      case 'promo-page-cabinet-program-edit':
      case 'promo-page-cabinet-program-date':
      case 'promo-page-cabinet-program-date-create':
      case 'promo-page-cabinet-program-date-edit':
        return CabinetTab.PROGRAM;

      case 'promo-page-cabinet-meetings':
      case 'promo-page-cabinet-meetings-contact':
        return CabinetTab.MEETINGS;

      case 'promo-page-cabinet-chats':
        return CabinetTab.DISCUSSIONS;

      case 'cabinet-news':
        return CabinetTab.NEWS;

      default:
        return CabinetTab.EVENT_SETTINGS;

    }
  }
}
